import React from 'react'
import { useContext, ChangeEvent  } from 'react'
import './UploadImage.css'
import { Context, Page } from '../contexts/AuthContext'
import dicomParser from 'dicom-parser'

const UploadImage = () => {
  const { setPage, setBase64Image } = useContext(Context)
  const goBack = () => setPage(Page.Home)
  const onloadAvaliableImage = (e: ProgressEvent<FileReader>) => {
    const base64Image = e.target?.result as string
    setBase64Image(base64Image)
    setPage(Page.ImageAnalysis)
  }
  const drawDICOM = (imageData: ImageData, pixelData: Uint16Array) => {
    let max = 0
    for(let i=0;i<pixelData.length;i++)
      max = max < pixelData[i] ? pixelData[i] : max
    for(let i=0;i<pixelData.length;i++) {
      let j = i * 4
      const value = (pixelData[i] / max) * 255
      imageData.data[j] = value
      imageData.data[j+1] = value
      imageData.data[j+2] = value
      imageData.data[j+3] = 255
    }
  }
  const onloadDICOM = (e: ProgressEvent<FileReader>) => {
    const arrayBuffer = e.target?.result
    const byteArray = new Uint8Array(arrayBuffer as any)
    const dataSet = dicomParser.parseDicom(byteArray)

    const canvas = document.createElement("canvas")
    canvas.width = dataSet.uint16("x00280011") ?? 0;
    canvas.height = dataSet.uint16("x00280010") ?? 0;

    const context = canvas.getContext("2d")
    if (!context) return
    const imageData = context.createImageData(canvas.width, canvas.height)
    const pixelData = new Uint16Array(arrayBuffer as any, dataSet.elements.x7fe00010.dataOffset);

    drawDICOM(imageData, pixelData)
    context.putImageData(imageData, 0, 0)

    canvas.toBlob(blob => {
      const reader = new FileReader()
      reader.onload = onloadAvaliableImage
      reader.readAsDataURL(blob as Blob)
    }, "image/jpeg")
  }
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0]
    if (!file) return
    const filename = file.name
    const extension = filename.split(".").slice(-1)[0]
    let reader = new FileReader()
    if(extension === "dcm") {
      reader.onload = onloadDICOM
      reader.readAsArrayBuffer(file)
    }
    else {
      reader.onload = onloadAvaliableImage
      reader.readAsDataURL(file)
    }
  }

  return (
    <div className="UploadImage">
      <button onClick={goBack} className="goBack"></button>
      <div>
        Para analizar la imagen de la próstata debes enviarla. <b>Ninguna imagen será almacenada</b> y usada sin consentimiento, siéntete seguro de enviarla.
      </div>
      <input onChange={handleChange} id="file" type="file" accept="image/*, */dicom, .dcm, image/dcm, */dcm, .dicom" />
      <label htmlFor="file">Adjuntar imagen</label>
    </div>
  )
}

export default UploadImage
