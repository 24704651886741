import React from 'react'
import './ImageAnalysis.css'
import { useState, useEffect, useContext  } from 'react'
import { Context, Page, Token } from '../contexts/AuthContext'

const ImageAnalysis = () => {
  const [classes, setClasses] = useState<Array<boolean>>([])
  const { token, setPage, base64Image } = useContext(Context)
  const goBack = () => setPage(Page.Home)
  const preprocessImage = (img: HTMLImageElement) => {
    const canvas_1 = document.createElement("canvas")
    const ctx_1 = canvas_1.getContext("2d")!
    canvas_1.width = 500
    canvas_1.height = 500

    const canvas_2 = document.createElement("canvas")
    const ctx_2 = canvas_2.getContext("2d")!
    canvas_2.width = 400
    canvas_2.height = 400

    const canvas_3 = document.createElement("canvas")
    const ctx_3 = canvas_3.getContext("2d")!
    canvas_3.width = 32
    canvas_3.height = 32

    ctx_1.drawImage(img, 0, 0, canvas_1.width, canvas_1.height) // 500 x 500
    let imageData = ctx_1.getImageData(50, 50, canvas_1.width - 50, canvas_1.height - 50)
    ctx_2.putImageData(imageData, 0, 0) // 400 x 400
    ctx_3.drawImage(canvas_2, 0, 0, 400, 400, 0, 0, 32, 32) // 32 x 32
    imageData = ctx_3.getImageData(0, 0, canvas_3.width, canvas_3.height)

    const data = imageData.data
    const image = []
    for (let i = 0; i < data.length; i += 4)
      image.push(data[i]/255)
    return image
  }
  const sendImage = async (image: Array<number>) => {
    const server = process.env.REACT_APP_SERVER || "http://localhost:4000"
    const response = await fetch(`${server}/api/analyze_image`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token as string
      },
      body: JSON.stringify({image})
    })
    const result = await response.json()
    let predictions: Array<number> = await Object.values(result.outputs)
    return predictions
  }
  const updateComponent = (predictions: Array<number>) => {
    let classes = predictions.map(e => e > 40)
    if (classes.every(e => !e))
      classes[4] = true
    if (classes[4]) {
      classes = classes.map(e => false)
      classes[4] = true
    }
    console.log(predictions)
    setClasses(classes)
  }
  const onloadOriginalImage = async (e: Event) => {
    const originalImage = e.target
    const image = preprocessImage(originalImage as HTMLImageElement)
    const predictions = await sendImage(image)
    updateComponent(predictions)
  }
  const requestPredictions = async (token: Token, base64Image: string) => {
    const img = new Image()
    img.src = base64Image
    img.onload = onloadOriginalImage
  }
  const Loading = () => {
    return (
      <>
        <div>Procesando</div>
        <div className="spinner"></div>
      </>
    )
  }
  const Predictions = () => {
    const labels = ["Perdida de bordes", "Quistes", "Cambio de intensidad", "Deformidades", "Ninguna"]
    return (
      <>
        <button onClick={goBack} className="goBack"></button>
        <div><img src={base64Image} alt="prostate" /></div>
        <div>
          Anomalías detectadas:
          {
            classes.map(
              (bool, index) => <div key={index}>{ bool ?  `* ${labels[index]}` : "" }</div>
            )
          }
        </div>
      </>
    )
  }

  useEffect(() => { requestPredictions(token, base64Image) }, [token, base64Image])

  return (
    <div className="ImageAnalysis">
      { classes.length === 0 ? <Loading /> : <Predictions /> }
    </div>
  )
}

export default ImageAnalysis
