import React from 'react'
import { useContext } from 'react'
import './Header.css'
import { Context, Page } from '../contexts/AuthContext'

interface HeaderProps {
  title: string
}

const Header = ({title}: HeaderProps) => {
  const { token, setPage } = useContext(Context)
  const goBack = () => setPage(Page.Home)

  return (
    <div className="Header">
      <h1 onClick={goBack}>{title}</h1>
      { token == null ? <a href="/Manual.pdf">Manual</a> : <></> }
    </div>
  )
}

export default Header
