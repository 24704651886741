import React from 'react';
import { Dispatch, SetStateAction } from 'react'

interface AuthContextProps {
  children: JSX.Element | Array<JSX.Element>
}

export type Token = string | null

export enum Page {
  Home,
  EditProfile,
  UploadImage,
  ShowForm,
  FormAnalysis,
  ImageAnalysis
}

interface AuthContext {
  age: number
  setAge: Dispatch<SetStateAction<number>>
  token: Token
  setToken: Dispatch<SetStateAction<Token>>
  page: Page
  setPage: Dispatch<SetStateAction<Page>>
  answers: Array<number>
  setAnswers: Dispatch<SetStateAction<Array<number>>>
  base64Image: string
  setBase64Image: Dispatch<SetStateAction<string>>
}

const defaultState = {
  age: 0,
  setAge: (_: number) => {},
  token: null,
  setToken: (_: Token) => {},
  page: Page.Home,
  setPage: (_: Page) => {},
  answers: [],
  setAnswers: (_: Array<number>) => {},
  base64Image: "",
  setBase64Image: (_: string) => {}
} as AuthContext

export const Context = React.createContext<AuthContext>(defaultState)

export function AuthContextProvider({ children }: AuthContextProps) {
  const [age, setAge] = React.useState<number>(0)
  const [token, setToken] = React.useState<Token>(null)
  const [page, setPage] = React.useState<Page>(Page.Home)
  const [answers, setAnswers] = React.useState<Array<number>>([])
  const [base64Image, setBase64Image] = React.useState<string>("")
  return (
    <Context.Provider value={{ age, setAge, token, setToken, page, setPage, answers, setAnswers, base64Image, setBase64Image }}>
      {children}
    </Context.Provider>
  )
}
