import React from 'react'
import { useContext } from 'react'
import './Form.css'
import { Context, Page } from '../contexts/AuthContext'

const questions = [
  [0, "Durante el último mes, ¿cuántas veces ha tenido la sensación de no vaciar completamente la vejiga después de orinar?"],
  [0, "Durante el último mes, ¿cuántas veces ha tenido que volver a orinar en menos de dos horas después de la última vez que orinó?"],
  [0, "Durante el último mes, ¿cuántas veces ha notado que empezando a orinar el chorro se detiene y vuelve a empezar?"],
  [0, "Durante el último mes, ¿cuántas veces ha tenido dificultad para aguantarse las ganas de orinar?"],
  [0, "Durante el último mes, ¿cuántas veces ha notado que orina sin fuerza?"],
  [0, "Durante el último mes, ¿cuántas veces ha tenido que esforzarse para comenzar a orinar?"],
  [1, "Durante el último mes, ¿cuántas veces ha tenido que levantarse a orinar entre la hora de acostarse y la hora de levantarse?"],
]

const answers_1 = [
  "Ninguna",
  "Menos de 1 en 5",
  "Menos de la mitad de las veces",
  "Casi la mitad de las veces",
  "Más de la mitad de las veces",
  "Casi siempre",
]

const answers_2 = [
  "Ninguna",
  "1 vez",
  "2 veces",
  "3 veces",
  "4 veces",
  "5 veces o más",
]

type AnswerProps = {question: number, value: string}

const Answer = ({question, value}: AnswerProps) => {
  return (
    <label>
      <input type="radio" name={`${question}`} value={value} />
      { value }
    </label>
  )
}

type QuestionProps = {index:number, value: (string | number)[]}

const Question = ({index, value}: QuestionProps) => {
  const text = value[1]
  const answers = value[0] === 0 ? answers_1 : answers_2
  return (
    <section>
      { text }
      <div className="Answers">
        {
          answers.map((answer, i) => <Answer key={i} question={index} value={answer} />)
        }
      </div>
    </section>
  )
}

const Form = () => {
  const { setPage, setAnswers, age } = useContext(Context)
  const goBack = () => setPage(Page.Home)
  const getAnswers = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const data = new FormData(e.currentTarget)
    const array = Array.from(data.values())
    if (array.length !== questions.length) {
      alert("Por favor, responde todas las preguntas")
      return
    }
    let answers = array.map(answer => {
      const index = answers_1.indexOf(answer as string)
      const index_2 = answers_2.indexOf(answer as string)
      return index !== -1 ? index : index_2
    })
    answers = [age, ...answers]
    console.log(answers)
    setAnswers(answers)
    setPage(Page.FormAnalysis)
  }

  return (
    <form onSubmit={getAnswers} className="Form">
      <section>
        Este formulario esta destinado para calcular tu puntaje de sintomas prostáticos. <b>No se va a guardar la información</b>, siente seguro de llenarlo.
      </section>
      <button onClick={goBack} className="goBack"></button>
      {
        questions.map((question, index) => <Question key={index} index={index} value={question} />)
      }
      <button className="SubmitButton">Enviar Respuestas</button>
    </form>
  )
}

export default Form
