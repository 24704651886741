import React from 'react'
import { useState, useEffect, useContext } from 'react'
import './EditProfile.css'
import { Context, Page } from '../contexts/AuthContext'

const EditProfile = () => {
  const { token, setPage } = useContext(Context)
  const goBack = () => setPage(Page.Home)
  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    age: "",
    email: "",
    password: ""
  })

  useEffect(() => {
    const fetchData = async () => {
      const server = process.env.REACT_APP_SERVER || "http://localhost:4000"
      const response = await fetch(`${server}/api/get_user_info`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token as string
        },
      })
      setUser(await response.json())
    }
    fetchData()
  }, [token])

  const handleChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUser({
      ...user,
      [e.currentTarget.name]: e.currentTarget.value
    })
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const server = process.env.REACT_APP_SERVER || "http://localhost:4000"
    const response = await fetch(`${server}/api/edit_profile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token as string
      },
      body: JSON.stringify( { user } )
    })
    const { result } = await response.json()
    alert(result ? "Cuenta actulizada" : "La cuenta no fue actulizada")
  }

  return(
    <form onSubmit={handleSubmit} className="EditProfile">
      <button onClick={goBack} className="goBack"></button>
      Nombre: <input onChange={handleChanges} type="text" name="first_name" defaultValue={user.first_name} />
      Apellido: <input onChange={handleChanges} type="text" name="last_name" defaultValue={user.last_name} />
      Edad: <input onChange={handleChanges} type="number" name="age" defaultValue={user.age} />
      Email: <input onChange={handleChanges} type="text" name="email" defaultValue={user.email} />
      Contraseña: <input onChange={handleChanges} type="password" name="password" />
      <button>Guardar</button>
    </form>
  )
}

export default EditProfile
