import React from 'react'
import { MouseEventHandler } from 'react'
import './AccessButton.css'

interface AccessButtonProps {
  content: string
  click: MouseEventHandler<HTMLButtonElement>
}

const AccessButton = ({content, click}: AccessButtonProps) => {
  return (
    <button className="AccessButton" onClick={click}>
      {content}
    </button>
  )
}

export default AccessButton
