import React from 'react'
import { useContext } from 'react'
import './Main.css'
import { Context, Page } from '../contexts/AuthContext'
import Access from './Access'
import Tools from './Tools'
import EditProfile from './EditProfile'
import UploadImage from './UploadImage'
import Form from './Form'
import FormAnalysis from './FormAnalysis'
import ImageAnalysis from './ImageAnalysis'

const Main = () => {
  const { token, page } = useContext(Context)
  const pages = {
    [Page.Home]: <Tools />,
    [Page.EditProfile]: <EditProfile />,
    [Page.UploadImage]: <UploadImage />,
    [Page.ShowForm]: <Form />,
    [Page.FormAnalysis]: <FormAnalysis />,
    [Page.ImageAnalysis]: <ImageAnalysis />,
  }

  return (
    <div className="Main">
      { token == null ? <Access /> : pages[page] }
    </div>
  )
}

export default Main
