import React from 'react'
import './FormAnalysis.css'
import { useState, useEffect, useContext  } from 'react'
import { Context, Page, Token } from '../contexts/AuthContext'

const FormAnalysis = () => {
  const [prediction, setPrediction] = useState<number | null>(null)
  const { token, setPage, answers } = useContext(Context)
  const goBack = () => setPage(Page.Home)

  const sendAnswers = async (token: Token, answers: Array<number>) => {
    const server = process.env.REACT_APP_SERVER || "http://localhost:4000"
    const response = await fetch(`${server}/api/analize_form_responses`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token as string
      },
      body: JSON.stringify({answers})
    })
    const output = await response.json()
    console.log(output)
    setPrediction(output)
  }
  const Loading = () => {
    return (
      <>
        <div>Procesando</div>
        <div className="spinner"></div>
      </>
    )
  }
  const calculateIPSS = (answers: Array<number>) => {
    answers = answers.slice(1)
    const score = answers.reduce((a, b) => a + b, 0)
    return score
  }
  const Analysis = () => {
    const score = calculateIPSS(answers)
    console.log(score)
    let nivel = ""
    if (score < 8) nivel = "LEVE"
    if (score > 7 && score < 20) nivel = "MODERADO"
    if (score > 19 && score < 36) nivel = "SEVERO"
    return (
      <>
        <button onClick={goBack} className="goBack"></button>
        <div>Tu calificación IPSS es <b>{nivel}</b>.</div>
        {
          nivel === "SEVERO" ? "Deberias contactar a un urólogo para que te de un diagnóstico apropiado." : ""
        }
        {
          nivel === "MODERADO" ? "Cuida tu salud y si el problema sigue contacta a un urólogo." : ""
        }
        <div>
          La página con los datos recolectados de un Google Forms da la
          predicción de <b>{ prediction && `${(prediction * 100).toFixed(2)}%` }</b>.
        </div>
      </>
    )
  }

  useEffect(() => { sendAnswers(token, answers) }, [token, answers])

  return (
    <div className="FormAnalysis">
      { !prediction ? <Loading /> : <Analysis /> }
    </div>
  )
}

export default FormAnalysis
