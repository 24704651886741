import React from 'react'
import { useContext, FormEvent } from 'react'
import { Context } from '../contexts/AuthContext'

const RegisterForm = () => {
  const [inputValues, setInputValues] = React.useState({
    first_name: "",
    last_name: "",
    age: 0,
    email: "",
    password: ""
  })

  const handleChanges = (event: FormEvent<HTMLInputElement>) => {
    setInputValues({
      ...inputValues,
      [event.currentTarget.name]: event.currentTarget.value
    })
  }

  const { setToken } = useContext(Context)

  const signup = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const server = process.env.REACT_APP_SERVER || "http://localhost:4000"
    const response = await fetch(`${server}/api/auth/signup`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(inputValues)
    })
    const token = response.headers.get("auth-token")
    setToken(token)
  }

  return (
    <>
      <div>Registro de Cuenta</div>
      <form onSubmit={signup}>
        Nombre: <input onChange={handleChanges} name="first_name" type="text" />
        Apellido: <input onChange={handleChanges} name="last_name" type="text" />
        Edad: <input onChange={handleChanges} name="age" type="number" />
        Correo: <input onChange={handleChanges} name="email" type="text" />
        Contraseña: <input onChange={handleChanges} name="password" type="password" />
        <button>Registrar</button>
      </form>
    </>
  )
}

export default RegisterForm
