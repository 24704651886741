import React from 'react'
import './App.css'
import Header from './components/Header'
import Main from './components/Main'
import { AuthContextProvider } from './contexts/AuthContext'

const App = () => {
  return (
    <div className="App">
      <AuthContextProvider>
        <Header title="ProstaVision" />
        <Main />
      </AuthContextProvider>
    </div>
  )
}

export default App
