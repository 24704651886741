import React from 'react'
import { useState, useContext, FormEvent } from 'react'
import { Context } from '../contexts/AuthContext'

const LoginForm = () => {
  const incorrectStyle = {boxShadow: "0 0 0 1px red"}

  const [style, setStyle] = useState({})

  const [inputValues, setInputValues] = useState({
    email: "",
    password: ""
  })

  const handleChanges = (event: FormEvent<HTMLInputElement>) => {
    setInputValues({
      ...inputValues,
      [event.currentTarget.name]: event.currentTarget.value
    })
  }

  const { setToken, setAge } = useContext(Context)

  const signin = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const server = process.env.REACT_APP_SERVER || "http://localhost:4000"
    const response = await fetch(`${server}/api/auth/signin`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(inputValues)
    })
    const { age } = await response.json()
    setAge(age)
    const token = response.headers.get("auth-token")
    if(token == null) {
      setStyle(incorrectStyle)
      alert("Datos incorrectos")
    }
    setToken(token)
  }

  return (
    <>
      <div>Iniciar Sesión</div>
      <form onSubmit={signin}>
        Correo: <input onChange={handleChanges} style={style} name="email" type="text" />
        Contraseña: <input onChange={handleChanges} style={style} name="password" type="password" />
        <button>Entrar</button>
      </form>
    </>
  )
}

export default LoginForm
