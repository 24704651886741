import React from 'react'
import { Dispatch, SetStateAction } from 'react'
import LoginForm from './LoginForm'
import RegisterForm from './RegisterForm'
import './Modal.css'

enum ModalMode {
  None,
  Register,
  Login
}

interface ModalProps {
  state: ModalMode
  setState: Dispatch<SetStateAction<ModalMode>>
}

const Modal = ({state, setState}: ModalProps) => {
  if (state === ModalMode.None) return <></>
  return (
    <div className="Modal" onClick={() => setState(ModalMode.None)}>
      <div className="Card" onClick={e => e.stopPropagation()}>
        {state === ModalMode.Login ? <LoginForm /> : <RegisterForm />}
      </div>
    </div>
  )
}

export default Modal
