import React from 'react'
import { useState } from 'react'
import AccessButton from './AccessButton'
import Modal from './Modal'
import robot from './robot.png'
import hospitalSign from './hospital_sign.png'

enum ModalMode {
  None,
  Register,
  Login
}

const Access = () => {
  let [state, setState] = useState(ModalMode.None)
  const registerModal = () => setState(ModalMode.Register)
  const loginModal = () => setState(ModalMode.Login)
  return(
    <>
      <div className="MessageContainer">
        <img width="100px" src={robot} alt="robot figure" />
        <div className="Message">ProstaVision es un sistema para concientizar a los hombres sobre enfermedades de la próstata</div>
      </div>
      <div className="Buttons">
        <AccessButton content="Registrar Cuenta" click={registerModal} />
        <img width="100px" src={hospitalSign} alt="hospital sign" />
        <AccessButton content="Iniciar Sesión" click={loginModal} />
      </div>
      <Modal state={state} setState={setState} />
    </>
  )
}

export default Access
