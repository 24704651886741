import React from 'react'
import { useContext } from 'react'
import './Tools.css'
import { Context, Page } from '../contexts/AuthContext'
import AccessButton from './AccessButton'
import robot from './robot.png'
import hospitalSign from './hospital_sign.png'

const Tools = () => {
  const { setToken, setPage } = useContext(Context)
  const editProfile = () => setPage(Page.EditProfile)
  const uploadImage = () => setPage(Page.UploadImage)
  const showForm = () => setPage(Page.ShowForm)
  const signoff = () => setToken(null)

  return(
    <>
      <div className="MessageContainer">
        <img width="100px" src={robot} alt="robot figure" />
        <div className="Message">ProstaVision es un sistema para concientizar a los hombres sobre enfermedades de la próstata</div>
      </div>
      <div className="ToolButtons">
        <div>
          <AccessButton content="Editar Perfil" click={editProfile} />
        </div>
        <div className="HorizontalButtons">
          <AccessButton content="Predecir Anomalías de la Imagen" click={uploadImage} />
          <img width="100px" src={hospitalSign} alt="hospital sign" />
          <AccessButton content="Predecir Gravedad de los Síntomas" click={showForm} />
        </div>
        <div>
          <AccessButton content="Cerrar Sesión" click={signoff} />
        </div>
      </div>
    </>
  )
}

export default Tools
